<template>
  <CForm ref="form" class="needs-validation"   >
    <CTextarea
        placeholder="Sipariş numaralarını altalta gir..."
        horizontal
        rows="9"
        v-model="data.orderIdsForGibs"
    />
  </CForm>
</template>

<script>
export default{
  name: "GIBForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},

    }
  }
}
</script>